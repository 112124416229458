// .card {
//     @media (max-width: 900px) {
//         display: flex;
//         margin: 0;

//         height: 100%;
//         width: 100%;
//         flex-wrap: wrap;
//     }
//     @media (max-width: 499px) {
//         height: 100%;
//         width: 100vw;
//     }
// }

.card__img {
    color: var(--colBlue2);
    width: 100%;
    height: 100vh;
    border-bottom: var(--borderYellow);
    display: flex;
    align-items: center;
    margin: 0;
    position: relative;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    background-image: var(--img);

    .content {
        position: absolute;
        right: 15%;
    }
    @media (max-width: 900px) {
        width: 100%;
        height: 500px;
        background-position: top;
    }

    @media (max-width: 499px) {
        width: 100%;
    }
    img {
        width: 20%;
        border-radius: 3px;
        bottom: -100px;
        position: relative;
    }
}

.card__title {
    position: relative;
    top: 0px;
    left: 0px;
    width: 100%;
    margin-bottom: 30px;
    padding-left: 15px;
    padding-top: 10px;
    color: var(--colBlue2);
    text-shadow: 1px 1px 1px var(--colBlue);

    font-size: 2rem;
    text-transform: uppercase;
    transition: all 0.2s ease;

    @media (max-width: 525px) {
        transition: all 0.2s ease-in;
        font-size: 26px;
    }
    @media (max-width: 900px) {
        left: 0;
        margin-bottom: 0;
        padding-top: 20px;
        padding-bottom: 20px;
    }

    span {
        display: block;
        position: relative;
        top: 6px;
        line-height: 0.85;
        opacity: 0.85;
    }
    span.small {
        color: var(--colBrown);
        position: relative;
        opacity: 1;
        font-size: 18px;
    }
}

@media (min-width: 1400px) {
    .card {
        display: flex;
        width: 100%;
        height: 100vh;
    }
}
