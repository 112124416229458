@import url(https://fonts.googleapis.com/css?family=Source+Sans+Pro:200,300,400,600,700,900,200italic,300italic,400italic,600italic,700italic,900italic);
@import url('https://fonts.googleapis.com/css2?family=Kelly+Slab&family=Pacifico&family=Play:wght@700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Tilt+Neon&display=swap');

:root {
    --colYellow: #dabb65;
    --colOrange: #e4c216;
    --colWhite: #fcfdff;
    --colBeige: #b2a680;
    --colGray: #8e9496;
    --colGray1: #4a4749;
    --colGray2: #333;
    --colGray3: #ebeef4;
    --colGreen: #92b5a7;
    --colBlue: #072634;
    --colBlue1: #6c7fa0;
    --colBlue2: #bfdbfe;

    --borderYellow: 1px ridge var(--colYellow);
    --borderWhite: 5px ridge var(--colWhite);

    --colOrange1: #bd4f0a;
    --colBrown: #3d3c3a;
    --colDarkGray: #252626;
    --colDark: #161212;
    --colBlack: #212121;

    --colBeige1: #c48660;

    --paddingTitle: 0px 10%;
    --borderGray: 5px ridge var(--colGray1);
}

* {
    box-sizing: border-box;
}

body,
.item {
    font-family: 'Tilt Neon', cursive;
    margin: 0;
    background-image: url(../../Assets/stacked-peaks-haikei2.svg);
}
// .col-3 {
//     width: 25%;
//     flex: 0 0 25%;
// }
// .col-4 {
//     width: 33.3333%;
// }
// .col-8 {
//     width: 66.6667%;
// }
// .col-9 {
//     width: 75%;
//     flex: 0 0 75%;
// }
// .col-12 {
//     width: 100%;
//     flex: 0 0 100%;
// }
.mlr15 {
    margin: 0 15px;
}
.mt20 {
    margin-top: 20px;
}
.mb20 {
    margin-bottom: 20px;
}
.contactForm,
.sectionWrapper {
    background-image: url(../../Assets/layered-peaks-haikei.svg);
}

.background-white {
    background: #fff;
}
.container {
    max-width: 1400px;
    margin: 0 auto;
}

.center {
    display: flex;
    justify-content: center;
    margin: 20px 0;
}
.flex {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}
.humaPadding {
    padding-top: 60px;
    background-color: var(--colWhite);
}

.paddingTopHash {
    padding-top: 50px;
}
.bgClear {
    background-image: url(../../Assets/stacked-peaks-haikei.svg);
}
.item {
    margin: 30px 0;
    border-radius: 5px;
    padding: 1%;
    border: 1px ridge #bebebe;
}

.pDarkTheme {
    color: var(--colBlue);
    font-size: 1rem;
    font-weight: 300;
}
.titleSlider {
    color: var(--colBeige);
    text-shadow: 1px 1px 1px black;
    font-size: 1.2rem;
}

h1 {
    font-family: 'Kelly Slab', sans-serif;
    font-size: 1.8rem;
    text-align: center;
    color: var(--colBeige);
    text-shadow: 1px 1px 1px black;
}

.itemList {
    display: flex;
    flex-direction: column;
    justify-content: left;
    cursor: default;
    li {
        color: var(--colWhite);
        font-size: 14px;
        font-weight: 600;
        list-style: circle;
        margin: 0px 10px 10px;
    }
    li:hover {
        color: currentColor;
        scale: 1;
    }
}
.mainCard {
    margin: 0 15px;
}
.mySwiper {
    margin: 0 20px;
}
//pagination

.pagination {
    display: flex;
    flex-direction: row;
    justify-content: center;
    cursor: pointer;

    li {
        font-size: 20px;
        color: #bebebe;
        font-weight: 600;
        list-style: none;
        margin: 0 10px;
        text-decoration: none;
    }
    li:hover,
    .selected {
        scale: 1.3;
    }
    li a:hover,
    .selected a {
        color: var(--colOrange);
    }
}

/* responsive */

@media screen and (max-width: 1200px) {
    //footer
    .ft1 p {
        font-size: 0.4rem;
    }
}
@media screen and (max-width: 999px) {
    //header-menu
    .main_h {
        height: 60px;
    }
    .btn2 {
        transform: translateX(-50%);
        h5 {
            display: none;
        }
    }

    .subMenu {
        position: relative;
        top: 0px;
        right: 0px;

        li {
            margin: 0px;
        }
        li a {
            background-color: transparent;
            border: none;
            text-transform: uppercase;
            text-align: center;
            color: var(--colBlue1);
            text-decoration: none;
            transition: all 0.4s linear;
            font-size: 0.7rem;
            width: 140px;
        }
    }
}

@media (max-width: 899px) {
    //header-menu
    header {
        z-index: 999999;
        width: 100%;
        box-shadow: 1px 1px 1px black;
        .slidingButton {
            margin: auto;
        }
        .btn2 {
            width: max-content;
            left: 100px;
        }
        .logo {
            scale: 0.6;
            position: absolute;
            left: 0px;
        }
    }
    .sticky {
        .logo {
            margin-top: 0px;
        }
    }
    .subMenu li a {
        max-width: 100px;
        background-color: transparent;
        border: none;
    }
    .mySwiper {
        margin: 0 auto;
        .slider {
            max-width: 300px;
        }
    }
}

@media screen and (max-width: 799px) {
    //header responsive

    .subMenu li a {
        width: 80px;
        padding: 0;
        font-size: 0.7rem;
    }
    .subMenu li {
        padding: 0;
    }
}

@media screen and (max-width: 699px) {
    //header responsive

    .titleSlider {
        font-size: 1.1rem;
        padding: 2% 0%;
        text-align: center;
    }
}
@media screen and (max-width: 599px) {
    //header responsive
    .slidingButton {
        position: relative;
        top: -10px;
        left: 30%;
    }
    .isActiveButton {
        background: transparent;
    }
    .subMenu .menuLi {
        width: fit-content;
        background: transparent;
        box-shadow: none;
        top: 6px;
        right: 150px;
    }
    .subMenu li a {
        padding: 0 5px;
        font-size: 0.6rem;
        width: max-content;
    }

    .titleSlider {
        padding: 2% 0%;
        text-align: left;
    }
}
@media screen and (max-width: 450px) {
    .contactForm {
        width: 90%;
    }
    .navBtn {
        transform: scale(0.6);
    }
    .btn2 {
        top: 30px;
    }
    .btn2 .buttonText {
        font-size: 0.6rem;
    }
    .slidingButton {
        position: relative;
        top: -10px;
        left: 10%;
    }
}
