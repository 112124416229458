.react-player-container {
    // border-top: var(--borderWhite);
    // border-bottom: var(--borderWhite);

    border: solid 15px #bebebe;
    border-bottom-color: #ddd;
    border-left-color: #eee;
    border-right-color: #bebebe;
    border-top-color: #ddd;
}

.player-wrapper,
.cardStyle {
    padding: 0px 15px;
    width: 80%;
}
.cardStyle {
    height: 100%;
}

@media screen and (max-width: 899px) {
    .player-wrapper {
        position: relative;
        padding-bottom: 56.25%; /* 16:9 aspect ratio (change as needed) */
        height: 0;
        overflow: hidden;
    }
}
