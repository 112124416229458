.mySwiper {
    .card {
        margin: 10px;
        border: none;
        box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19),
            0 6px 6px rgba(0, 0, 0, 0.6);
        border-radius: 3px;
        background: #fff;
        position: relative;
        height: 400px;

        overflow: hidden;
        display: flex;
        flex-direction: column;
    }

    .card__top {
        background-size: cover;
        position: relative;
        overflow: hidden;
    }

    .card__top img {
        width: 100%;
        transition: all 0.5s ease;
    }

    .card:hover img {
        transform: scale(1.5);
    }

    .card__bottom {
        max-height: 60%;
        text-align: justify;
        padding: 20px;
    }

    .card h1 {
        font-size: 18px;
        line-height: 1.5;
    }

    .card p {
        font-size: 16px;
        line-height: 1.2;
        color: var(--colBlue);
    }

    .card__overlay {
        text-align: center;
        background: rgba(7, 38, 52, 0.9);
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        display: none;
        z-index: 999;
        transition: all 0.5s ease;
    }

    .card:hover .card__overlay {
        display: block;
    }

    .card__overlay a {
        display: block;
        width: 100%;
        height: 100%;
        position: relative;
        top: 300px;
    }
}
