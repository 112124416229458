.scroll-to-top-btn {
    bottom: 5%;
    border: 0px;
    border-radius: 50%;
    background-color: var(--colBlue);
    cursor: pointer;
    display: block;
    padding: 0px;
    position: fixed;
    z-index: 99999999;
    right: 5%;
    width: 40px;
    height: 40px;
    &:hover {
        transform: scale(1.1);
        background-color: var(--colYellow);
    }
}

.hidden {
    display: none;
}

@media (max-width: 300px) {
    .scroll-to-top-btn {
        right: 40%;
    }
}
