@import url('https://fonts.googleapis.com/css2?family=Kelly+Slab&family=Pacifico&family=Play:wght@700&display=swap');
.link {
    text-decoration: none;
}

.logo-balzac {
    font-family: 'Kelly Slab', sans-serif;
    width: 350px;
    text-align: center;
    padding: 5px;
    border-radius: 5px;
    background-image: repeating-linear-gradient(
        90deg,
        #6c7fa0,
        var(--colBlue),
        #6c7fa0
    );
    box-shadow: 2px 2px 2px 2px var(--colYellow);
    .h1 {
        width: 300px;
        margin: 0 auto;
        border-top: 3px solid var(--colYellow);
        text-transform: uppercase;
        letter-spacing: 5px;
        font-size: 25px;
        font-weight: 600;
        color: var(--colYellow);
        text-shadow: 1px 1px 1px #000;
    }

    .separator {
        margin: 0 auto 0px;
        width: 250px;
        display: flex;
        align-items: center;
        .line {
            height: 3px;
            flex: 1;
            background-color: var(--colYellow);
            text-shadow: 2px 2px 2px #000;
        }
        .h3 {
            margin: 0 auto -2px;
            color: var(--colYellow);
            padding: 0 0.5rem;
            letter-spacing: 5px;
            font-size: 23px;
            font-weight: 600;
            text-transform: uppercase;
            text-shadow: 1px 1px 1px #000;
        }
    }

    .h4 {
        color: var(--colYellow);
        font-size: 17px;
        text-transform: uppercase;
        text-shadow: 2px 2px 2px #000;
    }
}

.logo-balzac:hover {
    box-shadow: 2px 2px 2px 2px var(--colGray);
    .line {
        background-color: var(--colOrange);
    }
    .h1 {
        margin: 0px auto;
        border-top: 3px solid var(--colGray);
    }
}
@media screen and (max-width: 599px) {
    .logo-balzac {
        width: 70%;
        padding: 1% 5%;
        .h1 {
            width: 100%;
            margin: 0px auto;
            letter-spacing: 1px;
            font-size: 20px;
        }

        .separator {
            margin: 0 auto;
            width: 100%;
            .line {
                height: 3px;
            }
            .h3 {
                padding: 0 0.5rem;
                letter-spacing: 1px;
                font-size: 18px;
            }
        }

        .h4 {
            color: var(--colYellow);
            font-size: 14px;
        }
    }
}
