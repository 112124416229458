.social {
    overflow: hidden !important;
    display: block;

    transition: all linear 200ms;
    float: left;
    margin: 0px 0px 0px 0px;
}
.clearfix::after {
    content: '';
    clear: both;
    display: table;
}
.social .img {
    border: none;
    display: block;
    margin-left: 5px;
    color: var(--colYellow) !important;
}
.social .img:first-child {
    margin-top: 5px;
    margin-bottom: 15px;
    transition: all linear 200ms;
    color: gray !important;
}
.social:hover .img:first-child {
    margin-top: -36px;
}
