.art-category {
    background-color: var(--colGreen);
    text-align: justify;
    height: 350px;
    margin: 40px auto;
    border-radius: 0px 40px 0% 40px;
    padding: 2rem 8%;
    border: 5px ridge #bebebe;
    max-width: 340px;
    h2 {
        color: var(--colBlue);
        margin: 0 10%;
    }
    p {
        font-size: 14px;
    }
    a {
        color: var(--colOrange1);
        font-size: 18px;
        &:hover {
            color: var(--colOrange);
        }
    }
}
