.balzacList {
    padding: 70px 0;
    background: var(--colBlue);
    border-top: var(--borderWhite);
    border-bottom: var(--borderWhite);

    .projectPhoto {
        color: var(--colGray);
        height: 530px;
        background-color: #ddc;
        border: solid 1.4rem #eee;
        border-bottom-color: #fff;
        border-left-color: #eee;
        border-radius: 2px;
        border-right-color: var(--colWhite);
        border-top-color: #ddd;
        box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.25) inset,
            0 5px 10px 5px rgba(0, 0, 0, 0.25);
        position: relative;
        margin-top: 60px;
        text-align: center;
        img {
            max-width: 100%;
        }
    }
    @media screen and (max-width: 900px) {
        .cardItem {
            flex-direction: column;
        }
    }

    div {
        padding: 10px;
    }

    .cardContent {
        width: 100%;
        padding: 0px 0 10px 0px;
    }
    .title2 {
        font-family: 'Kelly Slab', sans-serif;
        font-size: 1.4rem;
        font-weight: bold;
        text-transform: uppercase;
        padding: 10px;
    }

    .text2 {
        font-family: 'Source Sans Pro', sans-serif;
        font-size: 14px;
        overflow: hidden;
        max-height: 100px;
        text-align: justify;
    }
    @media screen and (max-width: 599px) {
        .cardContent {
            padding: 0px 0 10px 5px;
            .text2 {
                font-size: 0.8rem;
                max-height: 120px;
            }
        }
        .title2 {
            font-size: 0.6rem;
        }
    }
}
