// card huma

.record_wrap {
    width: 100%;
    backdrop-filter: blur(30px);
    box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.5);
    border-radius: 5px;

    opacity: 1;
    margin: 20px;
    .date {
        color: var(--colYellow);
        text-shadow: 1px 1px 1px var(--colBlue);
        padding: 20px;
    }
    .shortText {
        opacity: 0;
        padding: 20px;
        font-size: 0.75em;
        line-height: 1.4em;
        color: var(--colGray);
    }
}

.record {
    margin: auto;
    height: 350px;
    position: relative;
    border-radius: 3px;
    width: 100%;
    overflow: hidden;
    cursor: pointer;
    transition: box-shadow 300ms;
    &:hover {
        background-color: var(--colWhite);
    }
    text-align: center;
}

.record-display {
    box-shadow: 1px 1px 1px var(--colGray);
    width: 100%;
    height: 60%;
    background-size: cover;
    background-position: center center;
    display: flex;
    align-items: flex-end;
}
.btn_position {
    margin: 0;
    position: absolute;
    bottom: 2px;
    transform: translateX(-50%);
}
.record:hover .record-display {
    height: 20%;
    box-shadow: 3px 3px 3px var(--colGray);
}
.record:hover .shortText {
    opacity: 1;
}

.record-desc {
    margin: 20px 20px 10px;
    h1 {
        margin: 5px 0;
        font-size: 1.3em;
        font-weight: 700;
    }

    transition: opacity 400ms ease-in-out 190ms;
}

#record1 {
    transition: height 700ms cubic-bezier(0.54, 0.21, 0.18, 1.35);
}

.record:hover .record-desc {
    opacity: 1;
}

@media screen and (max-width: 1024px) {
    .record {
        height: 300px;
        width: 90%;
    }
}
