@import '../App/App.scss';
//menu_header

.footerMenu {
    margin: 25px 40px;
}
.footerMenu a {
    text-decoration: none;
    display: block;
}

#f3 {
    margin: 30px auto;
}
.footerCard {
    background-color: var(--colBlue);
    margin: 0;
    padding: 0px;
    border-top: var(--borderYellow);
    border-bottom: var(--borderYellow);
}
.footer {
    padding: 0px 30px 0;
    .pDarkTheme {
        font-size: 12px;
        color: var(--colGray);
    }
}
footer a {
    text-decoration: none;
}

.footerTitle,
a {
    text-decoration: none;
    color: #bebebe;
    margin-left: 5px;
}

.footerTitle:hover {
    color: var(--colOrange);
}
.footer-end {
    padding: 20px 5%;
    color: var(--colGreen);
    font-size: 0.6rem;
}
.ft1,
.ft2,
.ft3 {
    padding: 0px 25px;
}
.ft4 form input {
    display: block;
    margin: 10px auto;
    padding: 10px;
    width: 100%;
}
@media screen and (max-width: 699) {
}

@media screen and (max-width: 450px) {
    .footer {
        padding: 0px 30px 10px;
        .pDarkTheme {
            font-size: 50%;
        }
        .titleSlider {
            font-size: 0.8rem;
        }
    }
}
@media screen and (max-width: 299px) {
    .footer {
        input {
            transform: scale(0.6);
        }
    }
}
