#mouse-scroll {
    position: absolute;
    margin: auto;
    left: 50%;
    bottom: 20px;
    transform: translateX(-50%);
    z-index: 9999;
}
#mouse-scroll span {
    display: block;
    width: 10px;
    height: 10px;
    transform: rotate(45deg);
    transform: rotate(45deg);
    border-right: 2px solid var(--colOrange);
    border-bottom: 2px solid var(--colOrange);
    margin: 0 0 3px 10px;
}
#mouse-scroll .mouse {
    height: 51px;
    width: 34px;
    border-radius: 50px;
    transform: none;
    border: 2px solid #ffffff;
    top: 170px;
}
#mouse-scroll .down-arrow-1 {
    margin-top: 6px;
}
#mouse-scroll .down-arrow-1,
#mouse-scroll .down-arrow-2,
#mouse-scroll .down-arrow-3 {
    animation: mouse-scroll 1s infinite;
}
#mouse-scroll .down-arrow-1 {
    animation-delay: 0.1s;
    animation-direction: alternate;
}
#mouse-scroll .down-arrow-2 {
    animation-delay: 0.2s;
    animation-direction: alternate;
}
#mouse-scroll .down-arrow-3 {
    animation-delay: 0.3s;
    animation-direction: alternate;
}
#mouse-scroll .mouse-in {
    height: 15px;
    width: 5px;
    display: block;
    margin: 5px auto;
    background: var(--colOrange);
    position: relative;
    animation: animated-mouse 2s infinite;
}

@keyframes animated-mouse {
    0% {
        opacity: 1;
        transform: translateY(0);
    }
    100% {
        opacity: 0.2;
        transform: translateY(16px);
    }
}

@keyframes mouse-scroll {
    0% {
        opacity: 0;
    }
    50% {
        opacity: 0.5;
    }
    100% {
        opacity: 1;
    }
}
