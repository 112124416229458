@import '../../../src/Container/App/App.scss';

.contactForm {
    width: 80%;
    margin: 2rem auto 0;
    padding: 5% 2% 5%;

    form {
        padding-top: 0;
        margin-top: 0%;
        font-family: 'Kelly Slab', sans-serif;
        text-align: center;
        .button,
        input,
        textarea {
            border: 0;
            outline: 0;
            padding: 1em;
            border-radius: 3px;
            width: 100%;
            margin-top: 1em;
            box-shadow: 0 1px 1px 1px var(--colBeige);

            &:focus {
                box-shadow: 0 0px 2px var(--colOrange1) !important;
            }
        }

        textarea {
            height: 126px;
        }
        .messageSent {
            margin: 15px auto;
            box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
            border: 1px ridge var(--colGray);
            border-radius: 3px;
            background: var(--colBeige);
            text-transform: uppercase;
            padding: 15px 30px;
            font-weight: bold;
            font-size: 13px;
            letter-spacing: 0.4rem;
            color: var(--colBlue);
        }
        .show {
            display: block;
        }
        .hide {
            display: none;
        }
    }
    .toContact {
        font-family: 'Courier New', Courier, monospace;
        padding: 0;
        margin: 0 auto;
        text-align: center;
        width: max-content;
    }
}

@media screen and (max-width: 450px) {
    .contactForm {
        margin: 20px auto 0;
    }
}
@media screen and (max-width: 299px) {
    .contactForm form input,
    textarea {
        width: 100%;
        margin: 0;
    }
}
