.navLinks {
    display: flex;
    flex-direction: row;
    justify-content: center;
    cursor: pointer;

    .menuLi {
        display: flex;
        position: relative;
    }
    .isActiveButton,
    .a {
        padding: 0 2rem;
        text-transform: uppercase;
        font-weight: 600;
        height: 50px;
    }

    .isActiveButton {
        color: var(--colYellow);
        text-decoration: underline;
    }

    .a {
        text-decoration: none;
        cursor: pointer;
        font-size: 1rem;
        color: var(--colBeige);
    }

    a:hover {
        color: var(--colYellow);
    }
    li:hover .subMenu {
        display: flex;
        flex-direction: column;
    }
}

//responsive
.navBtn {
    display: none;
}

@media (max-width: 999px) {
    .main_h {
        position: fixed;
        height: 50px;
    }

    .navBtn {
        border: none;
        background-color: transparent;
        display: inline-block;
        position: fixed;
        top: 10px;
        right: 0rem;
        z-index: 10;
        cursor: pointer;
    }

    .navLinks {
        list-style: none;
        flex-direction: column;
        position: absolute;
        top: 32px;
        left: 0px;
        width: 100%;
        background-color: transparent;
        padding: 10px 0;
        perspective: 400px;
        li:hover .subMenu {
            display: flex;
            flex-direction: row;
        }
    }

    .navLinks .menuLi {
        margin: 0px;
        background: var(--colBlue);
        align-items: center;
        width: 100%;
        box-shadow: -2px 2px 5px black;
        animation: navAnimation 600ms ease forwards;
        transform: rotateX(90deg);
        opacity: 0;
        transform-origin: top;
    }
    .navLinks li:nth-child(2) {
        animation-delay: 200ms;
    }
    .navLinks li:nth-child(3) {
        animation-delay: 400ms;
    }
    .navLinks li:nth-child(4) {
        animation-delay: 600ms;
    }
    @keyframes navAnimation {
        to {
            transform: rotateX(0);
            opacity: 1;
        }
    }
    .navLinks .menuLi a {
        text-decoration: none;
        display: flex;
        justify-content: center;
        text-transform: uppercase;
        font-weight: bold;
    }
    .navLinks.showMenu {
        display: flex;
        flex-direction: column;
    }
    .navLinks.hideMenu {
        display: none;
    }
}
//subMenu
.subMenu {
    position: absolute;
    top: 40px;
    left: -10px;
    display: none;
    li {
        margin: auto;
    }
    li a {
        padding: 10px;
        background-color: var(--colYellow);
        border: 1px solid var(--colGray);
        text-transform: uppercase;
        text-align: center;
        color: var(--colBlue1);
        text-decoration: none;
        transition: all 0.4s linear;
        font-size: 0.7rem;
        width: 120px;
        &:hover {
            color: var(--colYellow);
            background-color: var(--colBlue);
            scale: none;
        }
    }
}
