.cardItemHuma {
    box-shadow: 3px 3px 3px var(--colGray);
    line-height: 1.1rem;
    padding: 1rem 4%;
    margin: 10px auto;
    min-height: 250px;
    background: var(--colBlue);
    border-radius: 5px;
    .title2 {
        color: var(--colBeige1);
        font-size: 15px;
        font-weight: 600;
        text-transform: uppercase;
        padding: 5px;
        text-align: justify;
    }
    .italic {
        font-style: italic;
    }
    .b {
        color: var(--colBeige);
    }
    .pDarkTheme {
        text-align: justify;
        font-size: 16px;
        color: var(--colYellow);
        .boldWhite {
            color: var(--colWhite);
            font-size: 14px;
        }

        a {
            font-size: 20px;
            text-decoration: none;
            color: var(--colWhite);
        }
        a:hover {
            color: var(--colGreen);
        }
    }
}
.cardItemHuma:hover {
    box-shadow: 5px 5px 5px var(--colGray), 5px 5px 5px var(--colGray),
        5px 5px 5px var(--colGray), -1px -1px 5px var(--colGray);
    background: #fff;
    transform: translateY(-18px);
    transition: 0.3s ease;

    .pDarkTheme,
    .boldWhite {
        color: var(--colBlue);
    }
}
