.articleItem {
    background-color: var(--colBlue1);
    margin: 10px 0;
    border-radius: 5px;
    p {
        padding: 20px;
        font-size: 14px;
        text-align: justify;
    }

    figure {
        text-align: center;
        border-radius: 5px;
        position: relative;
        padding: 30px;
        margin: 30px;
        height: 180px;
        img {
            border-radius: 3px;
            border: #bebebe dotted 1px;
            max-width: 300px;
            transition: all 1s ease;
        }
        figcaption {
            font-size: 10px;
        }
    }

    .itemDiv {
        font-size: 16px;
        font-weight: 600;
        text-decoration: underline;
    }
}

.containerGrid {
    justify-content: center;
    text-align: center;
    border-radius: 3px;
    margin: 20px;
    padding: 10px;
    width: 80%;
    display: grid;
    grid-template-columns: 5% 20% 20% 20% 20%;
    grid-template-rows: repeat(auto);
    column-gap: 5px;
    row-gap: 5px;
    background-color: #bebebe;
    border: 2px solid #c48660;
}
.one {
    background-color: #fff;
    border-radius: 5px;
}
