.left {
    margin: 0 auto;
    max-width: 300px;
    padding: 30px 50px;
    background: var(--colBlue);
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.5),
        0px 10px 20px rgba(0, 0, 0, 0.5);
    border-radius: 5px;
    .text1 {
        color: #cce5ed;
        font-size: 2rem;
    }

    .projectSpan {
        text-transform: capitalize;
        margin: 10% 0;
        color: #6690ba;
        font-size: 16px;
    }
    &:hover {
        transition: all 0.5s ease;
        transform: rotate(1deg);
    }
}
@media screen and (max-width: 350px) {
    .left {
        .text1 {
            color: #cce5ed;
            font-size: 1rem;
        }

        .projectSpan {
            text-transform: capitalize;
            margin: 10% 0;
            color: #6690ba;
            font-size: 10px;
        }
    }
}
