.humaPdfCard div {
    margin: 15px auto;
}
.humaPdfCard span {
    margin: 15px;
}
.pdfReader {
    display: flex;
    justify-content: center;
    margin-top: 90px;
}

.react-pdf__Document {
    // margin-top: 70px;
    // border-bottom: 2px solid var(--colBlue1);
}
.react-pdf__Page {
    border: 2px solid var(--colBlue2);
    width: fit-content;
    margin: 0px 0 20px;
}
