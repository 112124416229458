header {
    position: fixed;
    z-index: 999999;
    width: 100vw;
    top: 0;
    height: 60px;
    background: var(--colBlue);
    border-bottom: var(--borderYellow);
    .slidingButton {
        padding-left: 40px;
        margin-top: -13px;
    }
    .headerMenu {
        transition: margin 0.3s ease-in-out;
    }
    .logo-balzac {
        position: relative;
        left: -30%;
        margin-top: 5px;
    }
}
.visible {
    /* display: flex; */
    top: 0;
    transition: top 1s ease;
}

.hidden {
    /* display: none; */
    top: -80px;
    transition: top 0.4s ease-out;
}

//
//
//     .annonceImpot {
//         display: none;
//     }
// }

/***
Easing
***/

#easing {
    overflow: hidden;
    font-family: 'Source Sans Pro', sans-serif;
    width: 200px;
    border-bottom: 2px solid #c48660;
    text-align: center;
    animation: easing 10s ease-out;

    @keyframes easing {
        0% {
            transform: translateX(-220px);
        }

        100% {
            transform: translateX(0px);
        }
    }
}

// .annonceImpot {
//     color: #c48660;
//     text-shadow: 2px 2px 2px black;
//     font-family: 'Oswald', sans-serif;
//     font-size: 0.9rem;
// }
