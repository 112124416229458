article {
    margin: 0 auto 5% auto;
    padding: 0 5% 20px;
    max-width: 80vw;

    /* Typography
 –––––––––––––––––––––––––––––––––––––––––––––––––– */

    h2,
    h3 {
        color: var(--colBlue1);
    }

    h2 {
        font-weight: 900;
        font-size: 2.5rem;
        margin-bottom: 1.5rem;
    }

    h3 {
        font-weight: 700;
        font-size: 2rem;
        margin-bottom: 1.5rem;
    }

    /* Images
 –––––––––––––––––––––––––––––––––––––––––––––––––– */

    /* Half-width floating images */

    .left {
        float: left;
        margin-right: 2.5rem;
        margin-bottom: 1.5rem;
        width: 0%;
        padding: 0.5rem 0.5rem 0.25rem 0.5rem;
        border: 1px solid #fafafa;
        background-color: #f7f7f7;
        text-align: center;
    }

    .right {
        float: right;
        margin-left: 2.5rem;
        margin-bottom: 1.5rem;
        width: 50%;
        padding: 0.5rem 0.5rem 0.25rem 0.5rem;
        background-color: #f7f7f7;
        border: 1px solid #fafafa;
        text-align: center;
    }

    .infoGrid {
        max-width: 70vw;
    }

    .left img,
    .right img {
        width: 100%;
    }

    .right p,
    .left p {
        font-size: 0.85rem;
        margin: 0.5rem 1.25rem;
        font-weight: 300;
    }

    /* Styling images for mobile */

    @media screen and (max-width: 600px) {
        .left,
        .right {
            float: none;
            width: 96%;
            margin: 1.5rem auto;
        }
    }

    /* Images with pop-out captions -- must be full width or within a column */

    figure {
        display: block;
        position: relative;
        margin: 2rem 0 3rem 0;
    }

    figure img {
        width: auto;
        max-height: 500px;
        margin: 0 auto;
        padding: 0;
    }

    figcaption {
        position: absolute;
        background: var(--colBlue);
        color: white;
        opacity: 0;
        bottom: 0;
        left: -20%;
        margin: 3rem 1rem 1.5rem 1rem;
        padding: 1rem;
        width: inherit !important;
        border-radius: 5px;
        transition: all 0.6s ease;
    }

    figure:hover figcaption,
    figure:focus figcaption {
        opacity: 0.8;
        left: 0;
    }

    figure:before {
        content: '';
        position: absolute;
        background: rgba(255, 255, 255, 0.75);
        color: black;
        width: 1.8rem;
        height: 1.8rem;
        -webkit-border-radius: 12px;
        -moz-border-radius: 12px;
        border-radius: 15px;
        text-align: center;
        font-size: 1.5rem;
        line-height: 2rem;
        transition: all 0.6s ease;

        bottom: 0px;
        opacity: 0.8;
        margin: 3rem 1rem 1.25rem 1rem;
        padding: 0.7rem;
        font-weight: 900;
    }

    figure:hover:before,
    figure:focus:before {
        opacity: 0;
    }

    /* Blockquotes
 –––––––––––––––––––––––––––––––––––––––––––––––––– */

    /* Simple bar formatting */

    blockquote {
        font-size: 1rem;
        line-height: 1.5rem;
        margin: 2rem 1rem;
        padding: 0.8rem 2rem 0.8rem 1.3rem;
        border-left: 0.4rem solid #cccccc;
        border-radius: 10px;
        max-width: 400px;
    }

    /* Comment bubble formatting */

    .pullquote {
        padding: 0 0.25rem;
        margin: 2.5rem auto 3rem auto;
    }

    .pullquote blockquote {
        margin: 0;
        align-self: center;
        color: #333;
        font-size: 1em;
        font-weight: bold;
        line-height: 1.6rem;
        border: 4px solid #cccccc;
        border-radius: 20px;
        padding: 2rem;
    }

    .pullquote p {
        color: #333;
        position: relative;
        font-size: 0.9rem;
        line-height: 1.4rem;
        margin: 0;
        padding-top: 1rem;
        z-index: 1;
        margin-left: 0.8rem;
        padding-left: 10rem;
    }
}

@media screen and (min-width: 860px) {
    .pullquote {
        float: right;
        margin: 1.5rem 0.5rem 2.5rem 3rem;
    }

    .pullquote p {
        font-size: 0.95rem;
        line-height: 1.4rem;
    }

    .pullquote blockquote {
        font-size: 1.3rem;
        line-height: 1.9rem;
    }
}

/* Blockquote with big closing quotation mark */

.pullquote2-wrapper {
    display: flex;
    padding: 0 1rem;
}

.pullquote2 {
    position: relative;
    font-weight: 800;
    color: #333;
    padding: 2rem 0.5rem;
    width: 100%;
    max-width: 590px;
    z-index: 1;
    margin: 2rem auto 4rem auto;
    align-self: center;
    border-top: solid 1px;
    border-bottom: solid 1px;
}

.pullquote2 blockquote {
    position: relative;
    color: #333;
    font-size: 1.4rem;
    font-weight: 800;
    line-height: 1.7rem;
    margin: 0;
    border-left: none;
    border-radius: 0;
}

.pullquote2 p {
    position: relative;
    color: #555;
    font-size: 1.1rem;
    font-weight: 300;
    line-height: 1.5rem;
    margin: 0.5rem 0rem;
    z-index: 1;
    padding-left: 2.6rem;
    text-indent: -1rem;
}

@media screen and (min-width: 540px) {
    .pullquote2 blockquote {
        font-size: 2.2rem;
        line-height: 2.6rem;
    }

    .pullquote2 p {
        font-size: 1.3rem;
        line-height: 1.6rem;
    }
}
