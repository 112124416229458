@import '../../../src/Container/App/App.scss';
#parallax-container,
#parallax-container {
    font-family: 'Courier prime', sans-serif;
}
#parallax-container {
    background-image: url(../../../public/Images/maison.jpg);
    background-position: center center;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: cover;
    overflow: hidden;
    border-top: 3px ridge var(--colBeige);
    border-bottom: 3px ridge var(--colBeige);
}
.first-character {
    font-weight: 400;
    float: left;
    font-size: 80px;
    line-height: 1.8rem;
    padding: 10px 10px 20px;
}

.b {
    color: var(--colYellow);
}

#parallax-container h2,
#parallax-container-1 h2 {
    font-size: 50px;
    text-align: center;
    color: var(--colBeige);
    font-weight: normal;
    text-transform: uppercase;
    text-shadow: 2px 2px 2px var(--colBlack);
    background-color: rgba(255, 255, 255, 0.1);
    width: fit-content;
    margin: 0% auto;
}
#parallax-container h2:hover,
#parallax-container-1 h2:hover {
    color: var(--colYellow);
    text-shadow: 1px 1px 1px var(--colBlack);
    background-color: rgba(255, 255, 255, 0.5);
}
#parallax-container a,
#parallax-container-1 a {
    text-decoration: none;
}

// IMAGE

/* Section - Block */
/**************************/
#parallax-container .block,
#parallax-container-1 .block {
    margin: 20px;
    // clip-path: polygon(0 10%, 100% 0, 100% 90%, 0 100%);
    margin: 5px auto;
    text-align: justify;
    padding: 30px 10px;
    display: flex;
    background-color: none;
    p {
        padding: 30px;
        font-size: 13px;
    }
}

/* Section - Parallax */
/**************************/

.parallax-one,
.parallax-two,
.parallax-three {
    background-position: center center;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: cover;
    overflow: hidden;
}
.parallax-two,
.parallax-three {
    border-bottom: 3px ridge var(--colBeige);
}
.parallax-three {
    border-top: 3px ridge var(--colBeige);
}

#parallax-container .parallax-one {
    height: 100vh;
    padding-top: 320px;
    padding-bottom: 300px;
    background-image: url(../../../public/Images/maison.jpg);
}

#parallax-container .parallax-two {
    padding-top: 250px;
    padding-bottom: 230px;
    background-image: url(../../../public/Images/maison.jpg);
}
.parallax-three {
    padding-top: 300px;
    padding-bottom: 300px;
    background-image: url(../../../public/Images/IllustrationChlo.jpg);
}

@media (max-width: 899px) {
    #parallax-container .block {
        padding: 10px;
    }
    #parallax-container h2 {
        font-size: 30px;
    }
    #parallax-container .block,
    .block3 {
        padding: 30px;
        clip-path: none;
    }
}

@media screen and (max-width: 800px) {
    .parallax-one,
    .parallax-two,
    .parallax-three {
        padding-top: 400px;
        padding-bottom: 100px;
    }
}
