@import url(https://fonts.googleapis.com/css?family=Source+Sans+Pro:200,300,400,600,700,900,200italic,300italic,400italic,600italic,700italic,900italic);
//sliding button
.btn2 {
    font-family: 'Source Sans Pro', sans-serif;
    text-shadow: 1px 1px 1px black;
    max-width: 250px;
    overflow: hidden;
    cursor: pointer;
    position: relative;
    top: 15px;
    .buttonText {
        font-size: 0.9rem;
        font-weight: bold;
        text-transform: uppercase;
        z-index: 10;
        transition: 0.5s;
        text-decoration: none;
        color: var(--colYellow);
    }
    //flash
    h5 {
        position: absolute;
        top: -15px;
        right: 70px;
        font-size: 20px;
        opacity: 0;
        transition: 0.3s ease-out;
    }
}

.btn2:hover .buttonText {
    color: var(--colBeige);
    font-weight: bold;
    font-size: 0.8rem;
}

.btn2:hover h5 {
    margin-right: -10px;
    opacity: 1;
    color: var(--colYellow);
}

//button retour-avant
.btnProject {
    width: fit-content;
    height: fit-content;
    color: var(--colBeige);
    font-weight: 600;
    background: var(--colGray3);
    border: 1px ridge var(--colBlue2);
    border-radius: 2px;
    font-size: 14px;
    padding: 10px 30px;
    box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.3);
    &:hover {
        color: var(--colYellow);
        background-color: var(--colWhite);
    }
}
//yellow button

.button {
    width: max-content;
    margin: 0 auto;
    box-shadow: 1px 1px 1px var(--colGray);
    transition: all 1s ease-in-out;
    border: 1px ridge var(--colBeige);
    background: var(--colBlue);
    text-transform: uppercase;
    padding: 10px 30px;
    font-weight: 900;
    font-size: 12px;
    color: var(--colYellow);
    cursor: cursor;
    &:hover {
        color: var(--colBlue);
        background: var(--colYellow);
        box-shadow: 0 1px rgba(0, 0, 0, 0.2);
    }
}

.formButton {
    justify-content: center;
    padding: 1em;
    margin-top: 20px;
    cursor: pointer;

    &:hover {
        box-shadow: 0 1px 1px 1px rgba(#aaa, 0.6);
    }
}
@media screen and (max-width: 899) {
    .button {
        font-size: 10px;
    }
}
//transparent button
.transparentBtn {
    font-weight: bold;
    margin: 5px 10px;
    padding: 0.6% 40px;
    background-color: transparent;
    border: 3px solid var(--colBlue2);
    color: var(--colBlue);
    &:hover {
        color: var(--colBlue2);
        background-color: var(--colWhite);
    }
}
