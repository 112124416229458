.cardBlock {
    // max-width: 400px;
    box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.5);
    border-radius: 5px;
    padding: 20px;
    opacity: 1;

    h1 {
        font-family: 'Tilt Neon', cursive;
        font-size: 18px;
        text-transform: uppercase;
    }
    p {
        color: var(--colBlue);
        font-size: 14px;
    }
}
.cardTitleHover {
    &:hover {
        transition: all 300ms ease-in;
        backdrop-filter: blur(1px);
        box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.5);
    }
}
@media screen and (max-width: 899px) {
    .cardBlock {
        max-width: 100%;
        width: 100%;
        margin: 0 auto;
        padding: 20px 40px;
    }
}
@media screen and (max-width: 699px) {
    .cardBlock {
        padding: 20px 10px;
        h1 {
            text-align: start;
            font-size: 14px;
        }
    }
}
@media screen and (max-width: 499px) {
    .cardBlock {
        padding: 20px 10px;
        h1 {
            font-size: 10px;
        }
    }
}

//Card with image
// @import url('https://fonts.googleapis.com/css?family=Mukta+Malar:400,700');
// body {
//     color: #333;
//     font-size: 16px;
//     font-family: 'Mukta Malar', sans-serif;
//     padding: 0;
//     margin: 0;
//     background-color: #eee;
// }

.btn-options {
    width: 6px;
    height: 22px;
    display: block;
    background-color: #aaa;
    margin: 0.5em auto 0;
    border-radius: 4px;
}

.vote {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-bottom: 1em;

    &__stars {
        color: #aaa;
        font-size: 22px;
        margin-right: 12px;
    }

    &__num {
        color: #aaa;
    }
}

.persons {
    display: flex;
    margin-right: 12px;

    &__item {
        overflow: hidden;
        color: #c25;
        width: 24px;
        height: 24px;
        border: 2px solid #fff;
        background-color: #c25;
        border-radius: 50%;

        &:not(:first-child) {
            margin-left: -3px;
        }
    }
}

.likes {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    padding: 0.5em;
    padding-left: 0;
    border-top: 1px solid #eee;

    &__text {
        color: #555;
        font-size: 14px;

        b {
            font-weight: bold;
        }
    }
}

.card01 {
    max-width: 100%;
    max-height: fit-content;
    border: 1px solid #eee;
    margin: 1.5em 0.5em 0;
    background-color: #fff;
    border-radius: 3px;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);

    display: grid;
    grid-template-columns: 1em auto 1em;
    //grid-auto-rows: repeat(3, auto);

    &__img {
        max-height: 300px;
        box-shadow: 0 0 6px rgba(0, 0, 0, 0.3);
        margin: -1.5em 0 1em 0;
    }

    &__title {
        font-size: 1.5em;
        margin-bottom: 6px;
    }

    &__sub-title,
    &__desc {
        color: #555;
    }

    &__sub-title {
        display: block;
        font-size: 14px;
        margin-bottom: 1em;
    }

    &__desc {
        margin-bottom: 0.5em;
    }

    // blocks
    &__cover {
        grid-column: 2 / 3;
        grid-row: 1;
    }

    &__infos {
        grid-column: 2 / 3;
        grid-row: 2;
    }

    &__likes {
        grid-column: 2 / -1;
        grid-row: 3;
    }

    &__nav {
        grid-column: 3;
        grid-row: 1 / 3;
    }

    @media (min-width: 400px) {
        grid-template-columns: 1em repeat(2, auto) 1em;

        &__img {
            margin-right: 1.5em;
        }

        &__infos {
            grid-column: 3;
            grid-row: 1;
            margin-top: 1.5em;
        }

        &__nav {
            grid-column: 4;
        }
    }
}
