.slider-wrapper {
    padding: 0 10px;
    position: relative;
    height: 70vh;
    overflow: hidden;
    border-top: 1px ridge var(--colBeige);
    border-bottom: 1px ridge var(--colBeige);
    max-width: 800px;
    margin: auto;

    button {
        margin: 0 auto;
        max-width: 200px;
        box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
        transition: all 1s ease-in-out;
        border: none;
        background: var(--colOrange);
        border-radius: 30px;
        text-transform: uppercase;
        padding: 15px 40px;
        font-weight: 400;
        font-size: 13px;
        cursor: cursor;
    }

    button:hover {
        color: #ffffff;
        background: #222222;
        box-shadow: 0 1px rgba(0, 0, 0, 0.2);
    }

    .wrapper {
        max-width: 1335px;
        padding: 0 20px;
        margin: 0 auto;
    }

    .wrapper a {
        display: inline-block;
        margin: 5px;
    }

    .wrapper a:first-child {
        margin-left: 0;
    }

    .previousButton,
    .nextButton {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        z-index: 10;
        width: 32px;
        height: 32px;
        padding: 10px 10px 20px 10px;
        cursor: pointer;
    }
    .previousButton polygon,
    .nextButton polygon {
        fill: red;
    }
    .previousButton polygon:hover,
    .nextButton polygon:hover {
        fill: black;
    }

    .previousButton {
        left: 0;
    }

    .nextButton {
        right: 0;
    }

    .slider-content {
        text-align: center;
    }

    .slider-content .inner {
        padding: 0 70px;
        box-sizing: border-box;
        position: absolute;
        width: 100%;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
    .slider-content .inner img {
        height: 70vh;
        width: auto;
        padding: 0 70px;

        position: absolute;

        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
    .slider-content .inner h1 {
        font-weight: 900;
        margin: 0 auto;
        max-width: 840px;
        color: var(--colWhite);
        font-size: 64px;
        line-height: 1;
    }

    .slider-content .inner p {
        background-color: var(--colBlue);
        color: var(--colWhite);
        font-size: 14px;
        line-height: 1.5;
        position: absolute;
        top: 100px;
        text-shadow: 2px 2px 2px black;
    }

    @media (max-height: 500px) {
        .slider-wrapper,
        .slide {
            height: calc(100vh - 75px);
        }
    }

    @media (max-width: 640px) {
        .slider-wrapper,
        .slide {
            height: calc(80vh - 75px);
        }
    }

    @media (max-height: 600px) {
        .slider-content .inner h1 {
            font-size: 32px;
        }
    }

    @media (max-width: 640px) {
        .slider-content .inner h1 {
            font-size: 32px;
        }
    }
    //slider-animation

    .slide h1 {
        transition: all 0.3s ease;
        transform: translateY(-20px);
        opacity: 0;
    }

    .slide button {
        transition: all 0.3s ease;
        transform: translateY(20px);
        opacity: 0;
    }

    .slide p {
        transition: all 0.3s ease;
        transform: translateY(20px);
        opacity: 0;
    }

    .slide section * {
        transition: all 0.3s ease;
    }

    .slide section img {
        transform: translateX(-10px);
        opacity: 0;
    }

    .slide section span {
        transform: translateY(-10px);
        opacity: 0;
    }

    .slide section span strong {
        transform: translateY(10px);
        opacity: 0;
    }

    .slide.animateIn.previous h1,
    .slide.current h1,
    .slide.animateIn.next h1,
    .slide.animateIn.previous p,
    .slide.current p,
    .slide.animateIn.next p,
    .slide.animateIn.previous section *,
    .slide.current section *,
    .slide.animateIn.next section * {
        transform: translateX(0);
        transition-delay: 0.9s;
        opacity: 1;
    }
    .slide.animateIn.previous button,
    .slide.current button,
    .slide.animateIn.next button {
        transform: translateX(0);
        transition-delay: 0.1s;
        opacity: 1;
    }
    .slide.animateIn.previous p,
    .slide.animateIn.next p {
        transition-delay: 1.1s;
    }

    .slide.animateIn.previous button,
    .slide.animateIn.next button {
        transition-delay: 1.3s;
    }

    .slide.animateIn.previous section img,
    .slide.animateIn.next section img {
        transition-delay: 1.3s;
    }

    .slide.animateIn.previous section span,
    .slide.animateIn.next section span {
        transition-delay: 1.4s;
    }

    .slide.animateIn.previous section span strong,
    .slide.animateIn.next section span strong {
        transition-delay: 1.5s;
    }

    .slide.animateOut h1 {
        transition-delay: 0.3s;
    }

    .slide.animateOut p {
        transition-delay: 0.2s;
    }

    .slide.animateOut section span {
        transition-delay: 0.1s;
    }

    .slide.animateOut section span strong {
        transition-delay: 0s;
    }
}
